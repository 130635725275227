<template>
    <div class="py-16 px-2">
        <h1 class="text-red text-lg mb-6">
            Create Call
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/call/create" :formdata="formData">
                <TextInput type="text" label="Title" v-model="item.title" :required="false" ></TextInput>
                <div class="flex">
                    <TextInput class="w-1/2" type="text" label="First Name" v-model="item.first_name" :required="false" ></TextInput>
                    <TextInput class="w-1/2" type="text" label="Last Name" v-model="item.last_name" :required="false" ></TextInput>
                </div>
                <TextInput type="text" label="Email" v-model="item.email" :required="false" ></TextInput>
                <TextInput type="text" label="Phone" v-model="item.phone" :required="false" ></TextInput>
                <Wysiwyg label="Message" v-model="item.message"></Wysiwyg>
                <SelectInput label="Call Type" v-model="item.type" placeholder="Message..." :options="[{label:'Unknown',value:'unknown'},{label:'Support',value:'support'},{label:'Technical Support',value:'technical support'},{label:'Sales',value:'sales'}]" :required="false" ></SelectInput>
                <SearchSelectInput @item:removed="userRemoved" @item:selected="userSelected" :params="userSearchParams" :multiple="true" label="Assignees" v-model="item.users" :optiondisplay="userDisplay"></SearchSelectInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateCall',
    mixins: [Create],
    data: () => ({
        back: '/calls',
        action: '/call/',
        item: {
            title: '',
            status: 'Open',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            message:'',
            type:'unknown',
            users: [],
        },
        userSearchParams: {
            'model': 'User',
            'fields': ['id','name','email'],
            'action': '/search/',
        },
    }),
    computed: {
        formData() {
            return {
                'call':this.item,
            }
        },
    },
    props: [],
    methods: {
        userDisplay(item) {
            return item.email
        },
        userSelected(evt) {
            let index = this.item.users.findIndex((user)=>{return user.id == evt.item.id})
            if(index < 0) this.item.users.push(evt.item);
        },
        userRemoved(evt) {
            this.item.users.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
