<template>
    <div class="py-16 px-2">
        <h1 class="text-red text-lg mb-6">
            Edit Call
        </h1>
        <!--
        <ButtonTemplate :on_click="maybeDelete" btn_style="light" class="my-6" type="button">
            Delete Domain
        </ButtonTemplate>
        -->
        <div class="" v-if="item">
            <FormTemplate @response="formResponse" button="Save" method="post" action="/call" :formdata="formData">
                <TextInput type="text" label="Title" v-model="item.title" :required="false" ></TextInput>
                <div class="flex">
                    <TextInput class="w-1/2" type="text" label="First Name" v-model="item.first_name" :required="false" ></TextInput>
                    <TextInput class="w-1/2" type="text" label="Last Name" v-model="item.last_name" :required="false" ></TextInput>
                </div>
                <TextInput type="text" label="Email" v-model="item.email" :required="false" ></TextInput>
                <TextInput type="text" label="Phone" v-model="item.phone" :required="false" ></TextInput>
                <Wysiwyg label="Message" v-model="item.message"></Wysiwyg>
                <SelectInput label="Call Type" v-model="item.type" placeholder="Message..." :options="[{label:'Unknown',value:'unknown'},{label:'Support',value:'support'},{label:'Technical Support',value:'technical support'},{label:'Sales',value:'sales'}]" :required="false" ></SelectInput>
                <SearchSelectInput @item:removed="userRemoved" @item:selected="userSelected" :params="userSearchParams" :multiple="true" label="Assignees" v-model="item.users" :optiondisplay="userDisplay"></SearchSelectInput>
                <p>
                    Created On  {{getDate(item.created_at)}}
                </p>
                <p>
                    Last Updated On  {{getDate(item.updated_at)}}
                </p>
            </FormTemplate>
            <Notes :model="{id:item.id,type:'Call'}"></Notes>
        </div>
    </div>
</template>

<script>
import View from '../../../mixins/View'
import moment from 'moment'

export default {
    name: 'Call',
    mixins: [View],
    data: () => ({
        back: '/calls',
        userSearchParams: {
            'model': 'User',
            'fields': ['id','name','email'],
            'action': '/search/',
        },
        parameter: 'call_id',
        action: '/call/',
    }),
    computed: {
        formData() {
            return {
                'call':this.item,
            }
        },
    },
    props: [],
    methods: {
        getDate(date) {
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
        },
        userDisplay(item) {
            return item.email
        },
        userSelected(evt) {
            let index = this.item.users.findIndex((user)=>{return user.id == evt.item.id})
            if(index < 0) this.item.users.push(evt.item);
        },
        userRemoved(evt) {
            this.item.users.splice(evt.index,1);
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
